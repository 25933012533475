/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import '../../stylesheets/app.scss';
import { NavigationBarAndMenu, Footer } from '@library/shared-ui';
import { ScreenSizeConfigurator } from '../../../../../library/shared-ui/components/ScreenSizeConfigurator';

declare const window: any;

const Layout = ({ children, data }) => {
  const schema =
    children?.props?.children?.props?.children?.props?.data ||
    children?.props?.data ||
    data;
  const locales =
    children?.props?.children?.props?.children?.props ||
    children?.props ||
    data;

  const showToPercentage = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  return (
    <ScreenSizeConfigurator>
      <NavigationBarAndMenu
        featuredLinksMobileMenu={
          schema?.contentfulPageTemplate?.navbarMenuSection
            ?.featuredLinksMobileMenu
        }
        mobileNavigationLinks={
          schema?.contentfulPageTemplate?.navbarMenuSection
            ?.mobileNavigationLinks
        }
        featuredMobileSpot={
          schema?.contentfulPageTemplate?.navbarMenuSection?.featuredMobileSpot
        }
        ctaButtons={
          schema?.contentfulPageTemplate?.navbarMenuSection?.ctaButtons
        }
        logo={schema?.contentfulPageTemplate?.navbarMenuSection?.logo}
        mobileShopMenu={
          schema?.contentfulPageTemplate?.navbarMenuSection?.mobileShopMenu
        }
        navigationLinks={
          schema?.contentfulPageTemplate?.navbarMenuSection?.navigationLinks
        }
      />
      <main>{children}</main>
      <Footer
        columns={schema?.contentfulPageTemplate?.footerSection?.columns}
        infoColumn={schema?.contentfulPageTemplate?.footerSection?.infoColumn}
        logoContainer={
          schema?.contentfulPageTemplate?.footerSection?.logoContainer
        }
        bottomRow={schema?.contentfulPageTemplate?.footerSection?.bottomRow}
        pageContext={locales?.pageContext || locales?.allContentfulLocale}
      />
    </ScreenSizeConfigurator>
  );
};

export default Layout;
