import { Palette, Effects } from '@sezzle/sezzle-mui';

const headerFont =
  'Satoshi, Open Sans, Helvetica Neue, Helvetica, Arial, cursive, sans-serif';
const bodyFont = 'Satoshi, Open Sans, sans-serif';

const transparent = 'transparent';

export const sezzleTheme = {
  colors: {
    border: {
      main: Palette.GRAY,
      dark: Palette.DARK_GRAY,
    },
    effects: {
      dropShadow: Effects.DROP_SHADOW,
      dropShadowOverlay: Effects.DROP_SHADOW_OVERLAY,
      dropShadowLight: Effects.DROP_SHADOW_LIGHT,
    },
  },
  backgroundColor: Palette.WHITE,
  textColor: Palette.DARK_PURPLE,
  headerFont,
  bodyFont,
  primary: Palette.DARK_PURPLE,
  secondary: Palette.VIOLET,
  secondaryAlt: Palette.VIOLET_TRANSPARENT,
  tertiary: Palette.DARK_GRAY,
  primaryButton: Palette.VIOLET,
  primaryButtonText: Palette.WHITE,
  primaryButtonBorder: transparent,
  primaryButtonHover: Palette.WHITE,
  primaryButtonTextHover: Palette.VIOLET,
  primaryButtonBorderHover: Palette.VIOLET,
  secondaryButton: Palette.WHITE,
  secondaryButtonTextColor: Palette.VIOLET,
  secondaryButtonBorder: Palette.VIOLET,
  secondaryButtonHover: Palette.VIOLET,
  secondaryButtonTextColorHover: Palette.WHITE,
  secondaryButtonBorderHover: transparent,
  linkColor: Palette.VIOLET,
  linkColorHover: Palette.DARKER_PURPLE,
  backgroundColors: {
    backgroundGreen: Palette.GREEN_TRANSPARENT,
    backgroundBeige: Palette.PREMIUM_LIGHT_YELLOW,
    backgroundYellow: Palette.TANGERINE_TRANSPARENT,
    backgroundMint: Palette.SEAFOAM,
    backgroundPeach: Palette.AZALEA_LIGHTER_TRANSPARENT,
    backgroundPurple: Palette.VIOLET_LIGHTER_TRANSPARENT,
    backgroundGrey: Palette.LIGHT_GRAY,
    backgroundTransparent: Palette.BACKGROUND,
  },
  headerColors: {
    sezzlePurple: Palette.DARK_PURPLE,
    burntOrange: Palette.ORANGE,
  },
  buttonFont: bodyFont,
  secondaryLinkColor: Palette.WHITE,
  secondaryLinkActive: Palette.SEAFOAM,
  footerBackground: Palette.PREMIUM_LIGHT_YELLOW,
};

export default sezzleTheme;
