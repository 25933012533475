/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components';

export const GlobalWrapper = createGlobalStyle`
body, html {
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
}

*:focus {
  outline: -webkit-focus-ring-color auto 1px;
}

font-size: 1em !important;
background-color: ${(props: any) => props.theme.backgroundColor};

section {
  margin: 64px auto;
}

label {
  font-family: ${(props: any) => props.theme.bodyFont} !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: ${(props: any) => props.theme.textColor};
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: ${(props: any) => props.theme.headerFont} !important;
}

h1 {
  font-size: 2rem;
  line-height: 36.8px;
  font-weight: normal;
  margin: 0;
}

h2 {
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 24.15px;
  font-style: normal;
  padding: 0;
  margin-bottom: 16px;
  letter-spacing: normal;
}

h3 {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  line-height: 21.6px;
  letter-spacing: 0.05em;
}

p {
  font-family: ${(props: any) => props.theme.bodyFont} !important;
}

p.body__copy {
  &--large {
    font-size: 1.125rem;
    line-height: 32px;
    font-weight: normal;
    font-style: normal;
  }

  &--small {
    font-size: 1rem;
    line-height: 24px;
    font-weight: normal;
    font-style: normal;
  }
}

img {
  border-radius: 5px;
}

.row__reverse--small {
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.visible--xsmall {
  display: none !important;
  @media (max-width: 576px) {
    display: block !important;
  }
}

.visible--xsmall-opp {
  display: block !important;
  @media (max-width: 576px) {
    display: none !important;
  }
}

.visible--small {
  display: none !important;
  @media (max-width: 768px) {
    display: block !important;
  }
}

.visible--small-opp {
  display: block !important;
  @media (max-width: 768px) {
    display: none !important;
  }
}

.align--center {
  margin: auto;
}

.align--left {
  text-align: left;
}

.align--right {
  text-align: right;
}

.margin__top--1x {
  margin-top: 16px;
}

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input,
textarea,
select {
  box-shadow: none;
  width: 100%;
  background-color: ${(props: any) => props.theme.primaryButtonText};;
  box-sizing: border-box;
  padding: 0 32px 1px;
  height: 35px;
  font-family: ${(props: any) => props.theme.bodyFont};
  font-size: 1rem;
  letter-spacing: 0.4px;
  border: ${(props: any) => `1px solid ${props.theme.primary}`};
  transition: $transition;
  font-weight: 400;
  color: ${(props: any) => props.theme.primary};
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 27.5px;
  line-height: 32px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg fill='%23382757' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}
`;
